<template>
  <div>
    <BaseHeaderB></BaseHeaderB>
    <div class="errorbg"></div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.errorbg {
  background-image: url("../../public/404.png");
  background-size: contain;
  background-position: center;
  height: 75vh;
  margin-top: 0px;
}
</style>
